import * as React from "react"

import { SEO } from "../components/SEO";
import NavBar from '../components/NavBar';
import PhotoWall from '../components/PhotoWall';

import '../styles/global.css'

import { photos, thumbs } from "../photos";

const IndexPage = () => {
  return (
    <div id="top" className="pt-24">
      <NavBar />
      <div className="container px-3 mx-auto flex flex-wrap flex-col-reverse md:flex-row items-center">
        <PhotoWall
          thumbs={thumbs}
          photos={photos}
        />
      </div>
    </div>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)